<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Plano Profissional</v-toolbar-title>
                    <v-spacer/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <!-- <v-card-text v-if="loading" class="text-center my-5">
                        <h1>Aguarde, carregando informações...</h1>
                    </v-card-text> -->
                    <v-card-text class="text-center">
                        <div class="my-6" style="text-align: center; line-height: 30px;">
                            <h1>Aproveite o Plano Profissional por apenas R${{ professionalPlanValue }} por mês!</h1>
                        </div>

                        <div class="text-h5" style="text-align: center;">Todos os benefícios inclusos 👇🏻</div>

                        <v-row class="mt-4" justify="center">
                            <v-col cols="12" sm="6" md="4">
                                <v-card class="pa-4 highlighted-card professional-card" elevation="10">
                                    <div class="discount-banner">Novo!</div>

                                    <v-card-title class="white--text justify-center">
                                        Programa de Fidelidade
                                    </v-card-title>
                                    <v-card-text class="white--text text-body-1">
                                        <p>Seus clientes acumulam pontos que podem ser trocados por prêmios.</p>
                                        <p>Fidelize seus clientes e venda mais.</p>
                                        <p>Aumente o faturamento com vendas recorrentes.</p>
                                        <p>Melhore a experiência de compra dos clientes.</p>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-btn @click="saberMais('FIDELIDADE')" color="white" large block>
                                            <v-icon color="red">mdi-youtube</v-icon>
                                            Saber mais
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <v-card class="pa-4 highlighted-card professional-card" elevation="10">
                                    <div class="discount-banner">Novo!</div>

                                    <v-card-title class="white--text justify-center">
                                        Pix Automatizado
                                    </v-card-title>
                                    <v-card-text class="white--text text-body-1">
                                        <p>Segurança contra golpes e fraudes.</p>
                                        <p>Economia de tempo na conferência do pagamento.</p>
                                        <p>O pedido só é aprovado quando o dinheiro entra na sua conta.</p>
                                        <p>Praticidade para seu cliente efetuar o pagamento.</p>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-btn @click="saberMais('PAGAMENTO_ONLINE')" color="white" large block>
                                            <v-icon color="red">mdi-youtube</v-icon>
                                            Saber mais
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <v-card class="pa-4 highlighted-card professional-card" elevation="10">
                                    <div class="discount-banner">Novo!</div>

                                    <v-card-title class="white--text justify-center">
                                        Disparador de Whatsapp em Massa
                                    </v-card-title>
                                    <v-card-text class="white--text text-body-1">
                                        <p>Venda mais atraves de mensagens de marketing para todos os clientes.</p>
                                        <p>Envie mensagens para sua base de clientes de forma automática.</p>
                                        <p>Divulgue promoções, novidades ou avisos.</p>
                                        <p>Impulsione as vendas.</p>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-btn @click="saberMais('CAMPANHAS')" color="white" large block>
                                            <v-icon color="red">mdi-youtube</v-icon>
                                            Saber mais
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>

                        <div class="mt-12 mb-8 mx-2 text-h5">
                            Impulsionamos seu negócio para o próximo nivel! 🚀<br><br>
                            Ficou interessado e gostaria de saber mais? Clique no botão abaixo e converse conosco pelo whatsapp 👇🏻
                        </div>

                        <v-btn @click="tenhoInteresse" color="success" x-large block>
                            <v-icon>mdi-whatsapp</v-icon>
                            Quero os benefícios
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-content>

            <v-dialog v-model="showMoreInfoDialog" width="800px">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer/>
                        <span class="text-h5">{{ getTitle }}</span>
                        <v-spacer/>
                        <v-btn icon @click="showMoreInfoDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text
                        align="center"
                        justify="center"
                    >
                        <iframe
                            v-if="getVideoUrl"
                            class="video-container"
                            :src="getVideoUrl"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="tenhoInteresse" color="success" class="mb-6" x-large block>
                            <v-icon>mdi-whatsapp</v-icon>
                            Tenho interesse
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'PlanoProfissional',

    components: {
        BaseContainer,
    },

    data: () => ({
        moreInfoModule: null,
        showMoreInfoDialog: false,
    }),

    mounted() {
        this.pageView();
    },

    computed: {
        professionalPlanValue() {
            return this.formatPrice(259);
        },

        getVideoUrl() {
            if (this.moreInfoModule === 'FIDELIDADE') {
                return 'https://www.youtube.com/embed/227hohjRmVA';
            }
            if (this.moreInfoModule === 'PAGAMENTO_ONLINE') {
                return 'https://www.youtube.com/embed/VEpIePEvqCI';
            }
            if (this.moreInfoModule === 'CAMPANHAS') {
                return 'https://www.youtube.com/embed/s9r4pS0hQls';
            }
            return null;
        },

        getTitle() {
            if (this.moreInfoModule === 'FIDELIDADE') {
                return 'Programa de Fidelidade';
            }
            if (this.moreInfoModule === 'PAGAMENTO_ONLINE') {
                return 'Pix Automatizado';
            }
            if (this.moreInfoModule === 'CAMPANHAS') {
                return 'Disparador de Whatsapp em Massa';
            }
            return '';
        },
    },

    methods: {
        pageView() {
            this.$http.post('logger?type=plano-profissional');
        },

        tenhoInteresse() {
            this.$http.post('logger?type=plano-profissional-interesse');

            const msg = encodeURIComponent('Olá, quero SABER MAIS sobre o Plano Profissional');
            this.goToWhatsapp(msg);
        },

        saberMais(module) {
            this.moreInfoModule = module;
            this.showMoreInfoDialog = true;

            this.$http.post('logger?type=plano-profissional-video-' + module);
        },

        goToWhatsapp(msg) {
            const isMobile = !this.$vuetify.breakpoint.smAndUp;

            const link = isMobile ? 'whatsapp://send' : 'https://api.whatsapp.com/send';
            const phone = '5549988951210';

            window.open(`${link}?phone=${phone}&text=${msg}`);
        },
    },
}
</script>

<style scoped>
p {
    margin-bottom: 10px;
}

.v-card__title {
    white-space: pre-line;
}

.text-h5 {
    white-space: pre-line;
}

.highlighted-card:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.professional-card {
    background: linear-gradient(to right, #52bf54, #81d98f);
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
}

.discount-banner {
    position: absolute;
    top: 0;
    left: -23px;
    background-color: #d21c1c;
    color: white;
    padding: 5px 20px;
    font-weight: bold;
    transform: rotate(-45deg);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.video-container {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 1080px;
}
</style>